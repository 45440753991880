import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cx from 'classnames';
import s from './Page.scss';
import Loading from '../Loading';

const Page = ({
  children,
  className,
  containerClassName,
  loading,
  estimatedLoadingTime,
  loadingMessage,
  ...props
}) => {
  if (loading) {
    return (
      <Loading
        loadingMessage={loadingMessage}
        estimatedLoadingTime={estimatedLoadingTime}
      />
    );
  }
  return (
    <div className={cx(s.root, className)} {...props}>
      <div className={cx(s.container, containerClassName)}>{children}</div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  loadingMessage: PropTypes.shape(),
  estimatedLoadingTime: PropTypes.number,
  loading: PropTypes.bool,
};

Page.defaultProps = {
  className: undefined,
  containerClassName: undefined,
  loadingMessage: undefined,
  loading: false,
  children: undefined,
  estimatedLoadingTime: undefined,
};

export default withStyles(s)(Page);
